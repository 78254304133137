import { createStyle, getClassName, marginMixin } from "@laba/react-common";
import { Theme } from "model/theme";
import { getThemeStyleColor, themeTextMixin } from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export const useErrorTextStylesStatic = createStyle(
  (theme: Theme) => ({
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    withLeftMargin: {
      ...marginMixin({ left: 12 })
    }
  }),
  "ErrorText"
);

interface UseErrorTextStylesProps {
  withLeftMargin: boolean;
}

interface UseErrorTextStylesReturn {
  errorText: string;
}

export const useErrorTextStyles = (
  props: UseErrorTextStylesProps
): UseErrorTextStylesReturn => {
  const classes = useErrorTextStylesStatic();
  return {
    errorText: getClassName(
      classes.errorText,
      props.withLeftMargin ? classes.withLeftMargin : undefined
    )
  };
};
