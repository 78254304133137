import { getKeyObj } from "@laba/ts-common";
import { createHydratedMock } from "ts-auto-mock";

export enum MedicalRequestDefinitionFieldType {
  Numeric = "Numeric",
  Selector = "Selector",
  Checkbox = "Checkbox",
  CheckboxList = "CheckboxList",
  Date = "Date",
  DateTime = "DateTime",
  Text = "Text",
  SearchableList = "SearchableList",
  DeviceSelector = "DeviceSelector",
  MedicationSelector = "MedicationSelector",
  DoseSelector = "DoseSelector",
  RouteSelector = "RouteSelector",
  FrequencySelector = "FrequencySelector",
  DilutionSelector = "DilutionSelector",
  RateSelector = "RateSelector",
  DurationSelector = "DurationSelector",
  ProvidedByPatient = "ProvidedByPatient",
  Observations = "Observations",
  MedicationListSelector = "MedicationListSelector",
  PhpMedicationSelector = "PhpMedicationSelector",
  InsulineTable = "InsulineTable",
  MedicationPresentationSelector = "MedicationPresentationSelector",
  DiagnosticSelector = "DiagnosticSelector",
  PrescriptionList = "PrescriptionList",
  ProcedureSelector = "ProcedureSelector",
  RegistrationSelector = "RegistrationSelector"
}

export enum MRDFieldPropertyType {
  AdditiveCharacteristic = "additiveCharacteristic",
  Characteristic = "characteristic",
  Event = "event",
  IsEndDate = "isEndDate",
  IsStartDate = "isStartDate",
  MinElements = "minElements",
  MaxElements = "maxElements",
  Option = "option",
  RequestType = "requestType",
  Required = "required",
  Title = "title",
  Type = "type",
  Tags = "tags",
  Unit = "unit",
  WithCalculator = "withCalculator",
  AdditiveOption = "additiveOption",
  AdditiveUnit = "additiveUnit",
  AdditiveUnitSystem = "additiveUnitSystem",
  Condition = "condition",
  DefaultQuantity = "defaultQuantity",
  DefaultUnit = "defaultUnit",
  DefaultWarning = "defaultWarning",
  OptionSystem = "optionSystem",
  UnitSystem = "unitSystem",
  Placeholder = "placeholder",
  GenericType = "genericType",
  DilutionCharacteristic = "dilutionCharacteristic",
  SolventCharacteristic = "solventCharacteristic",
  ProblemSystem = "problemSystem",
  DoseUnit = "doseUnit",
  DoseUnitSystem = "doseUnitSystem",
  FlaskSize = "flaskSize",
  FlaskUnit = "flaskUnit",
  FlaskFractionUnit = "flaskFractionUnit",
  HideExtraDoseNow = "hideExtraDoseNow",
  Category = "category",
  HidePresentation = "hidePresentation",
  HideDose = "hideDose",
  PresentationRequired = "presentationRequired",
  PresentationType = "presentationType",
  PresentationOption = "presentationOption",
  PresentationOptionSystem = "presentationOptionSystem",
  Hide = "hide",
  NotModifiable = "notModifiable",
  NotModifiableDose = "notModifiableDose",
  NotModifiablePresentation = "notModifiablePresentation"
}

export interface MRDFieldPropertyBase<T extends string> {
  type: T;
  value?: string;
  text?: string;
}

export const MRDFieldPropertyKey = getKeyObj<MRDFieldPropertyBase<string>>(
  createHydratedMock<MRDFieldPropertyBase<string>>()
);

export interface MRDFieldBase<
  T extends MedicalRequestDefinitionFieldType,
  P extends string = string
> {
  type: T;
  column: number;
  properties: MRDFieldPropertyBase<P>[];
}

export const MRDFieldKey = getKeyObj<
  MRDFieldBase<MedicalRequestDefinitionFieldType>
>(createHydratedMock<MRDFieldBase<MedicalRequestDefinitionFieldType>>());

type MRDNumericFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Unit
  | MRDFieldPropertyType.UnitSystem
  | MRDFieldPropertyType.Required;

export type MRDNumericField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Numeric,
  MRDNumericFieldPropertyType
>;

type MRDProcedureReportFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.MinElements
  | MRDFieldPropertyType.MaxElements
  | MRDFieldPropertyType.Category
  | MRDFieldPropertyType.Placeholder
  | MRDFieldPropertyType.Required;

export type MRDProcedureReportField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.ProcedureSelector,
  MRDProcedureReportFieldPropertyType
>;

type MRDSelectorFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem
  | MRDFieldPropertyType.Required;

export type MRDSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Selector,
  MRDSelectorFieldPropertyType
>;

type MRDCheckboxFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title;

export type MRDCheckboxField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Checkbox,
  MRDCheckboxFieldPropertyType
>;

type MRDCheckboxListFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem;
export type MRDCheckboxListField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.CheckboxList,
  MRDCheckboxListFieldPropertyType
>;

type MRDDateFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Placeholder
  | MRDFieldPropertyType.Required;

export type MRDDateField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Date,
  MRDDateFieldPropertyType
>;

type MRDDateTimeFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Required
  | MRDFieldPropertyType.IsStartDate
  | MRDFieldPropertyType.Placeholder
  | MRDFieldPropertyType.IsEndDate;

export type MRDDateTimeField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DateTime,
  MRDDateTimeFieldPropertyType
>;

type MRDTextFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Required
  | MRDFieldPropertyType.Placeholder;

export type MRDTextField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Text,
  MRDTextFieldPropertyType
>;

type MRDSearchableListFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem
  | MRDFieldPropertyType.Placeholder
  | MRDFieldPropertyType.Required;

export type MRDSearchableListField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.SearchableList,
  MRDSearchableListFieldPropertyType
>;

type MRDDeviceSelectorFieldPropertyType =
  | MRDFieldPropertyType.RequestType
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Tags
  | MRDFieldPropertyType.Required;

export type MRDDeviceSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DeviceSelector,
  MRDDeviceSelectorFieldPropertyType
>;

type MRDMedicationSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Characteristic
  | MRDFieldPropertyType.Required
  | MRDFieldPropertyType.GenericType;

export type MRDMedicationSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.MedicationSelector,
  MRDMedicationSelectorFieldPropertyType
>;

type MRDRegistrationSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Hide
  | MRDFieldPropertyType.Required;

export type MRDRegistrationSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.RegistrationSelector,
  MRDRegistrationSelectorFieldPropertyType
>;

export enum MRDMedicationSelectorFieldPropertyTypeTypes {
  Search = "search",
  Select = "select"
}

export enum MRDPropertyTypeTypes {
  Medication = "medication",
  Fixed = "fixed"
}

export enum MRDDeviceSelectorFieldPropertyTypeTypes {
  Search = "search",
  Select = "select"
}

type MRDDoseSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Unit
  | MRDFieldPropertyType.UnitSystem
  | MRDFieldPropertyType.Required;

export type MRDDoseSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DoseSelector,
  MRDDoseSelectorFieldPropertyType
>;

type MRDRouteSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem
  | MRDFieldPropertyType.Required;

export type MRDRouteSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.RouteSelector,
  MRDRouteSelectorFieldPropertyType
>;

type MRDFrequencySelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Event
  | MRDFieldPropertyType.HideExtraDoseNow;

export type MRDFrequencySelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.FrequencySelector,
  MRDFrequencySelectorFieldPropertyType
>;

type MRDDilutionSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.DilutionCharacteristic
  | MRDFieldPropertyType.SolventCharacteristic
  | MRDFieldPropertyType.Unit
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.UnitSystem;

export type MRDDilutionSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DilutionSelector,
  MRDDilutionSelectorFieldPropertyType
>;

export type MRDRateSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem
  | MRDFieldPropertyType.Unit
  | MRDFieldPropertyType.UnitSystem
  | MRDFieldPropertyType.Event;

export type MRDRateSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.RateSelector,
  MRDRateSelectorFieldPropertyType
>;

export enum MRDDurationSelectorFieldPropertyTypeTypes {
  Period = "period",
  Day = "day",
  Start = "start"
}

export type MRDDurationSelectorFieldPropertyType = MRDFieldPropertyType.Type;

export type MRDDurationSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DurationSelector,
  MRDDurationSelectorFieldPropertyType
>;

export type MRDProvidedByPatientField =
  MRDFieldBase<MedicalRequestDefinitionFieldType.ProvidedByPatient>;

type MRDObservationsFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Placeholder;

export type MRDObservationsField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.Observations,
  MRDObservationsFieldPropertyType
>;

type MRDMedicationListSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.GenericType
  | MRDFieldPropertyType.Characteristic
  | MRDFieldPropertyType.MinElements
  | MRDFieldPropertyType.MaxElements
  | MRDFieldPropertyType.DoseUnit
  | MRDFieldPropertyType.DoseUnitSystem
  | MRDFieldPropertyType.Required
  | MRDFieldPropertyType.Placeholder
  | MRDFieldPropertyType.HidePresentation
  | MRDFieldPropertyType.HideDose
  | MRDFieldPropertyType.PresentationRequired
  | MRDFieldPropertyType.PresentationType
  | MRDFieldPropertyType.PresentationOption
  | MRDFieldPropertyType.PresentationOptionSystem;

export type MRDMedicationListSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.MedicationListSelector,
  MRDMedicationListSelectorFieldPropertyType
>;

type MRDPhpMedicationSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Characteristic
  | MRDFieldPropertyType.AdditiveCharacteristic
  | MRDFieldPropertyType.AdditiveUnit
  | MRDFieldPropertyType.AdditiveUnitSystem
  | MRDFieldPropertyType.FlaskSize
  | MRDFieldPropertyType.FlaskUnit
  | MRDFieldPropertyType.FlaskFractionUnit;

export type MRDPhpMedicationSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.PhpMedicationSelector,
  MRDPhpMedicationSelectorFieldPropertyType
>;

type MRDInsulineTableFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Unit
  | MRDFieldPropertyType.Condition;

export type MRDInsulineTableField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.InsulineTable,
  MRDInsulineTableFieldPropertyType
>;

type MRDDiagnosticSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Required;

export type MRDDiagnosticSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.DiagnosticSelector,
  MRDDiagnosticSelectorFieldPropertyType
>;

type MRDMedicationPresentationSelectorFieldPropertyType =
  | MRDFieldPropertyType.Title
  | MRDFieldPropertyType.Required
  | MRDFieldPropertyType.Type
  | MRDFieldPropertyType.Option
  | MRDFieldPropertyType.OptionSystem;

export type MRDMedicationPresentationSelectorField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.MedicationPresentationSelector,
  MRDMedicationPresentationSelectorFieldPropertyType
>;

type MRDPrescriptionListFieldPropertyType = MRDFieldPropertyType.Title;

export type MRDPrescriptionListField = MRDFieldBase<
  MedicalRequestDefinitionFieldType.PrescriptionList,
  MRDPrescriptionListFieldPropertyType
>;

export type MedicalRequestDefinitionField =
  | MRDNumericField
  | MRDSelectorField
  | MRDCheckboxField
  | MRDCheckboxListField
  | MRDDateField
  | MRDDateTimeField
  | MRDTextField
  | MRDSearchableListField
  | MRDDeviceSelectorField
  | MRDMedicationSelectorField
  | MRDDoseSelectorField
  | MRDRouteSelectorField
  | MRDFrequencySelectorField
  | MRDDilutionSelectorField
  | MRDRateSelectorField
  | MRDDurationSelectorField
  | MRDProvidedByPatientField
  | MRDObservationsField
  | MRDMedicationListSelectorField
  | MRDPhpMedicationSelectorField
  | MRDInsulineTableField
  | MRDDiagnosticSelectorField
  | MRDMedicationPresentationSelectorField
  | MRDProcedureReportField
  | MRDPrescriptionListField
  | MRDRegistrationSelectorField;
