import {
  createStyle,
  FlexAlignSelf,
  flexColumnMixin,
  flexItemMixin,
  flexRowMixin,
  getClassName,
  marginMixin
} from "@laba/react-common";
import { Theme, ThemeStyleColorVariant } from "model/theme";
import {
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";
import { StyleVariant, TextVariant } from "model/themeVariant";

export interface UnitInputStyleProps {
  hasError?: boolean;
  titleTextVariant: TextVariant;
  disabled?: boolean;
}

interface ClassName {
  root: string;
  unitText: string;
  title: string;
  inputsWrapper: string;
  errorText: string;
  fullWidth: string;
  inputItem: string;
}

const UnitInputStaticStyle = createStyle(
  (theme: Theme) => ({
    root: {
      ...flexColumnMixin({})
    },
    inputsWrapper: {
      ...flexRowMixin({
        gap: 8
      }),
      width: "100%"
    },
    errorText: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 }),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    errorTextWithError: {
      ...themeTextMixin(theme, TextVariant.Caption),
      ...marginMixin({ leftRight: 14, top: 4 }),
      color: getThemeStyleColor(theme, StyleVariant.Danger)
    },
    fullWidth: {
      width: "100%"
    },
    unitText: {
      ...themeTextMixin(theme, TextVariant.Subtitle2, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      ),
      textOverflow: "ellipsis"
    },
    unitTextDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    inputItem: {
      ...flexItemMixin({
        align: FlexAlignSelf.Center,
        grow: 1,
        basis: "50%"
      })
    }
  }),
  "UnitInput"
);

const UnitInputDynamicStyle = createStyle(
  (theme: Theme) => ({
    title: (props: UnitInputStyleProps) => ({
      ...marginMixin({ bottom: 8, top: 0 }),
      ...themeTextMixin(theme, props.titleTextVariant, 1),
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    })
  }),
  "UnitInputDynamic"
);

export const UnitInputStyle = (props: UnitInputStyleProps): ClassName => {
  const staticClasses = UnitInputStaticStyle();
  const dynamicClasses = UnitInputDynamicStyle(props);

  return {
    errorText: props.hasError
      ? staticClasses.errorTextWithError
      : staticClasses.errorText,
    fullWidth: staticClasses.fullWidth,
    inputsWrapper: staticClasses.inputsWrapper,
    root: staticClasses.root,
    unitText: getClassName(
      staticClasses.inputItem,
      staticClasses.unitText,
      props.disabled ? staticClasses.unitTextDisabled : undefined
    ),
    title: dynamicClasses.title,
    inputItem: staticClasses.inputItem
  };
};
