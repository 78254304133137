import { Quantity, QuantityUnit } from "model/primitives";
import { isNumberZeroOrUndefined, Optional } from "@laba/ts-common";
import { isUndefined } from "lodash-es";
import { Medication, MedicationAmount } from "./medication";

export const getEquivalentUnitFromMedication = (
  value?: Quantity,
  equivalentUnit?: QuantityUnit,
  unitReferenceValues?: MedicationAmount[]
): Quantity => {
  const valueUnitReference = unitReferenceValues?.find(
    healthcareUnit => healthcareUnit.unit === value?.unit
  );

  const equivalentUnitReference = unitReferenceValues?.find(
    healthcareUnit => healthcareUnit.unit === equivalentUnit
  );

  const valueQuantity = value?.quantity;
  const equivalentUnitReferenceValue = equivalentUnitReference?.value ?? 0;
  const valueUnitReferenceValue = valueUnitReference?.value ?? 0;

  if (
    valueUnitReferenceValue === 0 &&
    equivalentUnit === valueUnitReference?.unit
  ) {
    return {
      quantity: valueQuantity,
      unit: equivalentUnit
    };
  }

  if (
    isNumberZeroOrUndefined(equivalentUnitReferenceValue) ||
    isNumberZeroOrUndefined(valueUnitReferenceValue) ||
    isUndefined(valueQuantity)
  ) {
    return {
      quantity: undefined,
      unit: equivalentUnit
    };
  }

  const equivalentUnitValue =
    (valueQuantity * equivalentUnitReferenceValue) / valueUnitReferenceValue;

  return {
    quantity: equivalentUnitValue,
    unit: equivalentUnit
  };
};

export const getNormalizedUnitFromMedication = (
  value?: Quantity,
  unitReferenceValues?: MedicationAmount[]
): Optional<number> => {
  const valueUnitReference = unitReferenceValues?.find(
    healthcareUnit => healthcareUnit.unit === value?.unit
  );
  return isNumberZeroOrUndefined(valueUnitReference?.value)
    ? undefined
    : Number(value?.quantity) / Number(valueUnitReference?.value);
};

export const convertNumberToUnitValueFromMedication = (
  value?: number,
  unit?: QuantityUnit,
  unitReferenceValues?: MedicationAmount[]
): Optional<number> => {
  const unitReference = unitReferenceValues?.find(
    healthcareUnit => healthcareUnit.unit === unit
  );
  return isNumberZeroOrUndefined(unitReference?.value)
    ? undefined
    : Number(unitReference?.value) * Number(value);
};

export const getMedicationMaxUnits = (
  medication?: Medication
): Optional<number> => {
  return medication?.rules?.maxUnits;
};

export const getMedicationMinUnits = (
  medication?: Medication
): Optional<number> => {
  return medication?.rules?.minUnits;
};
