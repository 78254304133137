import React from "react";
import { FC } from "@laba/react-common";
import { UnitInput, UnitInputProps } from "../UnitInput";

export interface UnitDetailInputProps
  extends Omit<
    UnitInputProps,
    | "onBlurUnit"
    | "autocompleteUnitSelectedType"
    | "withUnitSelector"
    | "onChangeUnit"
    | "getUnitOptionFromValue"
    | "isUnitEditable"
  > {
  className?: string;
}

export const UnitDetailInput: FC<UnitDetailInputProps> = ({ ...props }) => {
  return <UnitInput {...props} withUnitSelector isUnitEditable={false} />;
};
