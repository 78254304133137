import { FC, getClassName } from "@laba/react-common";
import React from "react";
import { isEmpty } from "lodash-es";
import { useErrorTextStyles } from "./useErrorTextStyles";

interface ErrorTextProps {
  showError?: boolean;
  error?: string;
  className?: string;
  withLeftMargin?: boolean;
}
export const ErrorText: FC<ErrorTextProps> = ({
  showError,
  error,
  className,
  withLeftMargin = true
}) => {
  const classes = useErrorTextStyles({ withLeftMargin });
  const show = showError ?? !isEmpty(error);
  return show ? (
    <p className={getClassName(classes.errorText, className)}>{error}</p>
  ) : null;
};
