import {
  createStyle,
  FlexAlignItems,
  flexRowMixin,
  getClassName,
  StyleVariant,
  TextVariant,
  Theme,
  ThemeStyleColorVariant
} from "@laba/react-common";

import {
  getThemeStyleColor,
  getThemeStyleState,
  themeTextMixin
} from "model/themeUtils";

interface Props {
  maxLines?: number;
  textVariant?: TextVariant.Body2 | TextVariant.Subtitle2;
  textGap?: number;
  coloredBackground?: boolean;
  disabled?: boolean;
}

interface CheckboxStyleClassNames {
  root?: string;
  checkboxContainer: string;
  text: string;
  withoutPadding: string;
}

const useCheckboxStyle = createStyle(
  (theme: Theme) => ({
    coloredBackground: {
      backgroundColor: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.Background
      )
    },
    textColor: {
      color: getThemeStyleColor(
        theme,
        StyleVariant.Primary,
        ThemeStyleColorVariant.GrayDark
      )
    },
    textColorDisabled: {
      color: getThemeStyleState(theme, StyleVariant.Primary).disabled
    },
    withoutPadding: { padding: 0 }
  }),
  "Checkbox"
);

const useCheckboxDynamicStyle = createStyle(
  (theme: Theme) => ({
    subtitle2TextVariant: (props: Props) => ({
      ...themeTextMixin(theme, TextVariant.Subtitle2, props.maxLines)
    }),
    body2TextVariant: (props: Props) => ({
      ...themeTextMixin(theme, TextVariant.Body2, props.maxLines)
    }),
    checkboxContainer: (props: Props) => ({
      ...flexRowMixin({
        alignItems: FlexAlignItems.Center,
        gap: props.textGap
      })
    })
  }),
  "DynamicCheckbox"
);

export const useCheckboxStyleClassNames = (
  props: Props
): CheckboxStyleClassNames => {
  const classes = useCheckboxStyle();
  const dynamicClasses = useCheckboxDynamicStyle(props);

  return {
    root: props.coloredBackground ? classes.coloredBackground : undefined,
    checkboxContainer: dynamicClasses.checkboxContainer,
    text: getClassName(
      props.disabled ? classes.textColorDisabled : classes.textColor,
      props.textVariant === TextVariant.Body2
        ? dynamicClasses.body2TextVariant
        : dynamicClasses.subtitle2TextVariant
    ),
    withoutPadding: classes.withoutPadding
  };
};
