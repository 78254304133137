import React, { ReactElement } from "react";
import { getClassName } from "@laba/react-common";
import { Noop } from "@laba/ts-common";
import {
  OptionsConfig,
  SelectInput
} from "components/inputs/SelectInput/SelectInput";
import {
  UnitAutocompleteType,
  UnitInput
} from "components/inputs/UnitInput/UnitInput";
import { useSelectWithUnitInputStyles } from "./useSelectWithUnitInputStyles";

export interface SelectWithUnitInputProps<V> {
  className?: string;

  placeholder?: string;
  selectValue?: V;
  selectOptions: OptionsConfig<V>[];
  onChangeSelect?: (value?: V) => void;
  unitErrorText?: string;
  selectErrorText?: string;
  onSelectBlur?: Noop;
  selectNoOptionsText?: string;
  selectInputDisabled?: boolean;
  selectCompareValues?: (v1: V, v2: V) => boolean;

  maxValue?: number;
  minValue?: number;
  unitValue?: string;
  quantityValue?: number;
  onChangeUnit?: (newValue?: string) => void;
  onChangeQuantity?: (newValue?: number) => void;
  unitOptions?: OptionsConfig<string>[];
  unitInputDisabled?: boolean;
  onUnitInputBlur?: Noop;
  onUnitInputBlurUnit?: Noop;
  clearable?: boolean;
  autocompleteDisabledType?: UnitAutocompleteType;
  autocompleteUnitSelectedType?: UnitAutocompleteType;
  withUnitSelector?: boolean;
}

export const SelectWithUnitInput = <V,>({
  className,

  placeholder,
  selectValue,
  selectOptions,
  onChangeSelect,
  selectErrorText,
  unitErrorText,
  onSelectBlur,
  selectNoOptionsText,
  selectInputDisabled,
  selectCompareValues,

  quantityValue,
  onChangeQuantity,
  unitOptions = [],
  unitValue,
  onChangeUnit,
  maxValue,
  minValue,
  unitInputDisabled,
  onUnitInputBlur,
  onUnitInputBlurUnit,
  withUnitSelector,
  clearable = false,
  autocompleteDisabledType,
  autocompleteUnitSelectedType
}: SelectWithUnitInputProps<V>): ReactElement => {
  const classes = useSelectWithUnitInputStyles();

  const handleOnChangeSelect = (value?: V) => {
    if (value === undefined) {
      onChangeUnit?.(undefined);
      onChangeQuantity?.(undefined);
    }
    onChangeSelect?.(value);
  };

  return (
    <div className={getClassName(className)}>
      <div className={classes.selectAndUnitInputContainer}>
        <SelectInput<V>
          clearable={clearable}
          placeholder={placeholder}
          options={selectOptions}
          onChange={handleOnChangeSelect}
          value={selectValue}
          disabled={selectInputDisabled}
          errorText={selectErrorText}
          onBlur={onSelectBlur}
          noOptionsText={selectNoOptionsText}
          showHelperOrErrorText
          fullWidth
          compareValues={selectCompareValues}
        />
        <UnitInput
          quantityValue={quantityValue}
          onChangeQuantity={onChangeQuantity}
          onChangeUnit={onChangeUnit}
          unitOptions={unitOptions}
          unitValue={unitValue}
          maxValue={maxValue}
          minValue={minValue}
          disabled={unitInputDisabled}
          errorText={unitErrorText}
          onBlur={onUnitInputBlur}
          onBlurUnit={onUnitInputBlurUnit}
          showHelperOrErrorText
          autocompleteDisabledType={autocompleteDisabledType}
          autocompleteUnitSelectedType={autocompleteUnitSelectedType}
          withUnitSelector={withUnitSelector}
        />
      </div>
    </div>
  );
};
